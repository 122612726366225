import home from "./routes/home.js"
import users from "./routes/users.js"
import favorites from "./routes/favorites.js"
import general from "./routes/general.js";
import intro from "./routes/intro.js";
import faq from "./routes/faq.js";
import services from "./routes/services.js"
import notifications from "./routes/notifications.js"
import admin from "./routes/admin.js"
export default [
  // {
  //   path: "/",
  //   name: "default",
  //   meta: { title: "Dashboard", authRequired: true },
  //   component: () => import("../views/dashboards/default.vue")
  // },
  {
    path: "/",
    redirect: "/dashboard/home",
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../components/layouts.vue"),
    children: [
      ...home,
      ...users,
      ...favorites,
      ...general,
      ...intro,
      ...faq,
      ...services,
      ...notifications,
      ...admin
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",

    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/account/logout"),
    meta: {
      title: "Logout",
      authRequired: true,

    }
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
];
