import { createWebHistory, createRouter } from "vue-router";
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {

  const publicPages = ["/login"];
  const authpage = !publicPages.includes(routeTo.path);
  const loggeduser = localStorage.getItem("currentUser");

  if (!authpage)
    console.log("login page");
  else if (authpage && !loggeduser) {
    return next("/login");
  }
  else {
    const decoded_user_data = JSON.parse(loggeduser);
    const user_roles = decoded_user_data?.permissions.map((item) => item.permission.permission_en);
    console.log('ro', user_roles);
    const page_roles = routeTo.meta.auth_roles;
    console.log('pro', page_roles);
    let auth_status = false;
    for (let i = 0; i < user_roles?.length; i++) {
      // console.log("role :", user_roles[i]);
      // console.log("page roles :", page_roles);
      if (page_roles?.includes(user_roles[i])) {
        auth_status = true;
        break;
      } else {
        auth_status = false;
      }
    }
    if (auth_status) {
      // next();
      console.log("auth complete");
    } else {
      router.push("/");
      console.log("not allowed");
      next();
    }
  }
  
  next();

});

// router.beforeResolve(async (routeTo, routeFrom, next) => {
//   // Create a `beforeResolve` hook, which fires whenever
//   // `beforeRouteEnter` and `beforeRouteUpdate` would. This
//   // allows us to ensure data is fetched even when params change,
//   // but the resolved route does not. We put it in `meta` to
//   // indicate that it's a hook we created, rather than part of
//   // Vue Router (yet?).
//   try {
//     // For each matched route...
//     for (const route of routeTo.matched) {
//       await new Promise((resolve, reject) => {
//         // If a `beforeResolve` hook is defined, call it with
//         // the same arguments as the `beforeEnter` hook.
//         if (route.meta && route.meta.beforeResolve) {
//           route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
//             // If the user chose to redirect...
//             if (args.length) {
//               // If redirecting to the same route we're coming from...
//               // Complete the redirect.
//               next(...args);
//               reject(new Error("Redirected"));
//             } else {
//               resolve();
//             }
//           });
//         } else {
//           // Otherwise, continue resolving the route.
//           resolve();
//         }
//       });
//     }
//     // If a `beforeResolve` hook chose to redirect, just return.
//   } catch (error) {
//     return;
//   }

//   document.title = routeTo.meta.title + " | " + appConfig.title;
//   // If we reach this point, continue resolving the route.
//   next();
// });

export default router;
