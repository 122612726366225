export default [
  {
    path: "/terms-of-use",
    name: "terms",

    component: () => import("@/views/data/general/terms-of-use.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "terms",
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy",

    component: () => import("@/views/data/general/privacy-policy.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "privacy",
    },
  },
  {
    path: "/about-app",
    name: "about",

    component: () => import("@/views/data/general/about-app.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "about",
    },
  },
  {
    path: "/customer-service",
    name: "customer-service",

    component: () => import("@/views/data/general/customer-service.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "customer-service",
    },
  },
  {
    path: "/branches-information",
    name: "branches",

    component: () => import("@/views/data/general/branches/branches-information.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "branches",
    },
  },
  {
    path: "/branches-information/add-branch",
    name: "add-branch",

    component: () => import("@/views/data/general/branches/add-branch.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "add-branch",
    },
  },
  {
    path: "/branches-information/show-branch/:id",
    name: "show-branch",

    component: () => import("@/views/data/general/branches/show-branch.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "show-branch",
    },
  },
  {
    path: "/branches-information/edit-branch/:id",
    name: "edit-branch",

    component: () => import("@/views/data/general/branches/edit-branch.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "edit-branch",
    },
  },
  {
    path: "/check-books",
    name: "check-books",

    component: () => import("@/views/data/general/check-books.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "check-books",
    },
  },
];
