export default [
  {
    path: "/intropage",
    name: "intropage",

    component: () => import("@/views/data/general/intro/intropage.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "intropage",
    },
  },
  {
    path: "/intropage/show-intropage/:id",
    name: "show-intropage",

    component: () => import("@/views/data/general/intro/show-intropage.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "show-intropage",
    },
  },
  {
    path: "/intropage/edit-intropage/:id",
    name: "edit-intropage",

    component: () => import("@/views/data/general/intro/edit-intropage.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "edit-intropage",
    },
  },
];
