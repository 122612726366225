import { createStore } from "vuex";

import modules from "./modules";

const store = createStore({
  state: {
    // baseUrl: "http://192.168.0.103:2024/",
    // mediaUrl: "http://192.168.0.103:2024",
    // serverUrl: "http://192.168.0.103:2024",
    baseUrl: "https://mobile.ar-bank.sd/api/",
    mediaUrl: "https://mobile.ar-bank.sd/api",
    serverUrl: "https://mobile.ar-bank.sd/api",

    currentUser: {},
    token: null,
    // token: 'WTU9v6cgR9MKZN47EYdBHrh1rWnUm1Ea',
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      console.log(state.user);
      state.user = value;
    },
    addUserData(state, value) {
      state.currentUser = value;

      localStorage.setItem("user", JSON.stringify(value));
      console.log("userData :", state.currentUser);
    },
    changeLang() {
  
  
      if (localStorage.getItem("current_language") == "ar") {
  
  
  
        let bootstrap = document.querySelector('#bootstrap-lang-changer')
        bootstrap.setAttribute("href", "/css/bootstrap.rtl.min.css")
        setTimeout(() => {
          console.log("loading end");
        }, 300);
  
      } else {
  
        let bootstrap = document.querySelector('#bootstrap-lang-changer')
        bootstrap.setAttribute("href", "/css/bootstrap.min.css")
  
        setTimeout(() => {
          console.log("loading end");
        }, 300);
  
      }
    }
  },
  action: {
  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});

export default store;
