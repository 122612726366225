<template>
  <router-view></router-view>
</template>

<script>
// import store from "./state/store";
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  methods: {
    logout(){
         localStorage.removeItem("currentUser")
      this.$router.push("/login");
    }
  },
  mounted() {
    // setTimeout(() => this.$store.dispatch("changeLang"), 100);
    


let timeout;
let resetTimer = ()=> {
    clearTimeout(timeout);
    timeout = setTimeout(this.logout, 300000);
}

window.onload = resetTimer;
document.onmousemove = resetTimer;
document.onkeypress = resetTimer;
document.onscroll = resetTimer;
document.onclick = resetTimer

  },
};
</script>

<style lang="scss">


  
</style>
