export default [
  {
    path: "/services",
    name: "services",

    component: () => import("@/views/data/service-status.vue"),
    meta: {
      auth_roles: ["All", "Service Status"],
      authRequired: true,
      title: "services",
    },
  },
  {
    path: "/service-pricing",
    name: "servicePricing",

    component: () => import("@/views/data/service-pricing.vue"),
    meta: {
      auth_roles: ["All", "Service Status"],
      authRequired: true,
      title: "servicePricing",
    },
  },
];
