import { createApp } from 'vue'

import App from './App.vue'
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';

import popups from "@/services/popups";
import http from "@/services/http";
import $e from "@/services/$e";
import Login from "@/services/Login";

import { vMaska } from "maska"
import { i18n } from "./i18n.js";
import { initFirebaseBackend } from './authUtils'
import { configureFakeBackend } from './helpers/fake-backend';

import BootstrapVueNext from 'bootstrap-vue-next'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css"


// Datatable Modules
// import DataTable from 'datatables.net-vue3';
// import Select from 'datatables.net-select';

// store
import store from '@/state/store'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';






const app = createApp(App);


// Watch for changes in the i18n locale and dynamically import the corresponding Bootstrap CSS


// Set the initial locale (this can be based on user preference or other logic)




// DataTable.use(Select)


app
  .use(store)
  .use(router)
  .use(require('vue-chartist'))
  .use(BootstrapVueNext)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(i18n)
  .use(registerScrollSpy)
  .use(popups)
  .use(http)
  .use($e)
  .use(Login)
  // .component('DataTable', DataTable)


  .directive("maska", vMaska)
  .mount('#app')
