export default [
  {
    path: "/profile",
    name: "profile",

    component: () => import("@/views/admin/profile.vue"),
    meta: {
      auth_roles: ["All", "Users Management", "Users Favorites", "General", "Settings"],
      authRequired: true,
      title: "profile",
    },
  },
  {
    path: "/users",
    name: "users",

    component: () => import("@/views/admin/users.vue"),
    meta: {
      auth_roles: ["All", "Settings"],
      authRequired: true,
      title: "users",
    },
  },
  {
    path: "/users/add-user",
    name: "add_user",

    component: () => import("@/views/admin/add-user.vue"),
    meta: {
      auth_roles: ["All", "Settings"],
      authRequired: true,
      title: "add_user",
    },
  },
  {
    path: "/users/show-user/:id",
    name: "show_user",

    component: () => import("@/views/admin/show-user.vue"),
    meta: {
      auth_roles: ["All", "Settings"],
      authRequired: true,
      title: "show_user",
    },
  },
  {
    path: "/users/edit-user/:id",
    name: "edit_user",

    component: () => import("@/views/admin/edit-user.vue"),
    meta: {
      auth_roles: ["All", "Settings"],
      authRequired: true,
      title: "edit_user",
    },
  },
  {
    path: "/users-activities",
    name: "activities",

    component: () => import("@/views/admin/users-activities.vue"),
    meta: {
      auth_roles: ["All", "Settings"],
      authRequired: true,
      title: "activities",
    },
  }
];
