export default [
  {
    path: "beneficiaries",
    name: "beneficiaries",

    component: () => import("@/views/users/favorites/favorites-beneficiaries.vue"),
    meta: {
      auth_roles: ["All", "Users Favorites"],
      authRequired: true,
      title: "beneficiaries",
    },
  },
  {
    path: "meters",
    name: "favorite-meters",

    component: () => import("@/views/users/favorites/favorites-meters.vue"),
    meta: {
      auth_roles: ["All", "Users Favorites"],
      authRequired: true,
      title: "meters",
    },
  },
  {
    path: "numbers",
    name: "favorite-numbers",

    component: () => import("@/views/users/favorites/favorites-numbers.vue"),
    meta: {
      auth_roles: ["All", "Users Favorites"],
      authRequired: true,
      title: "numbers",
    },
  },
];
