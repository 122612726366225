export default [
  {
    path: "/create",
    name: "create",

    component: () => import("@/views/users/create-accounts.vue"),
    meta: {
      auth_roles: ["All", "Users Management"],
      authRequired: true,
      title: "create",
    },
  },
  {
    path: "/create-new-account",
    name: "create_new_account",

    component: () => import("@/views/users/create-new-account"),
    meta: {
      auth_roles: ["All", "Users Management"],
      authRequired: true,
      title: "create",
    },
  },
  {
    path: "/update-existing-account",
    name: "update_existing_account",

    component: () => import("@/views/users/update-existing-account"),
    meta: {
      auth_roles: ["All", "Users Management"],
      authRequired: true,
      title: "create",
    },
  },
  {
    path: "/create/customer-overview/:id",
    name: "customer-overview",

    component: () => import("@/views/users/customer-overview.vue"),
    meta: {
      auth_roles: ["All", "Users Management"],
      authRequired: true,
      title: "customer-overview",
    },
  },
  {
    path: "/all-users",
    name: "all-users",

    component: () => import("@/views/users/all-accounts.vue"),
    meta: {
      auth_roles: ["All", "Users Management"],
      authRequired: true,
      title: "all-users",
    },
  },
  {
    path: "/all-users/account-details/:id",
    name: "account-details",

    component: () => import("@/views/users/account-details.vue"),
    meta: {
      auth_roles: ["All", "Users Management"],
      authRequired: true,
      title: "account-details",
    },
  },
  {
    path: "/all-users/account-details/login-history/:id",
    name: "login-history",

    component: () => import("@/views/users/login-history.vue"),
    meta: {
      auth_roles: ["All", "Users Management"],
      authRequired: true,
      title: "login-history",
    },
  },
  {
    path: "/all-users/account-details/user-transactions/:id",
    name: "user-transactions",

    component: () => import("@/views/users/view-user-transactions.vue"),
    meta: {
      auth_roles: ["All", "Users Management"],
      authRequired: true,
      title: "user-transactions",
    },
  },
  {
    path: "/active-accounts",
    name: "active",

    component: () => import("@/views/users/active-accounts.vue"),
    meta: {
      auth_roles: ["All", "Users Management"],
      authRequired: true,
      title: "active",
    },
  },
  {
    path: "/inactive-accounts",
    name: "inactive",

    component: () => import("@/views/users/inactive-accounts.vue"),
    meta: {
      auth_roles: ["All", "Users Management"],
      authRequired: true,
      title: "inactive",
    },
  },
];
