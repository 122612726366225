export const state = {
  layoutType: 'vertical',
  layoutWidth: 'fluid',
  leftSidebarType: 'dark',
  topbar: 'dark',
  loader: false,
  lang: "ar",
  mode:'light'
}

export const mutations = {
  CHANGE_LAYOUT(state, layoutType) {
    state.layoutType = layoutType;
  },
  CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
    state.layoutWidth = layoutWidth;
  },
  CHANGE_LEFT_SIDEBAR_TYPE(state, leftSidebarType) {
    state.leftSidebarType = leftSidebarType;
  },
  CHANGE_TOPBAR(state, topbar) {
    state.topbar = topbar;
  },
  LOADER(state, loader) {
    state.loader = loader
  },
  // CHANGE_LANG(state, lang){
  //   state.current_language = lang
  // },
  CHANGE_MOD(state,mode){
    console.log("done",mode);
    
    state.mode= mode
  }
}

export const actions = {
  changeLayoutType({ commit }, { layoutType }) {
    commit('CHANGE_LAYOUT', layoutType);
  },

  // changeLang() {
  //   if (localStorage.getItem("current_language") == "ar") {



  //     let bootstrap = document.querySelector('#bootstrap-lang-changer')
  //     bootstrap.setAttribute("href", "/css/bootstrap.rtl.min.css")
  //     setTimeout(() => {
  //       console.log("loading end");
  //     }, 300);

  //   } else {

  //     let bootstrap = document.querySelector('#bootstrap-lang-changer')
  //     bootstrap.setAttribute("href", "/css/bootstrap.min.css")

  //     setTimeout(() => {
  //       console.log("loading end");
  //     }, 300);

  //   }
  // },

  changeLayoutWidth({ commit }, { layoutWidth }) {
    commit('CHANGE_LAYOUT_WIDTH', layoutWidth)
  },

  changeLeftSidebarType({ commit }, { leftSidebarType }) {
    commit('CHANGE_LEFT_SIDEBAR_TYPE', leftSidebarType)
  },

  changeTopbar({ commit }, { topbar }) {
    commit('CHANGE_TOPBAR', topbar)
  },

  changeLoaderValue({ commit }, { loader }) {
    commit('LOADER', loader)
  },
  changeMode({commit},payload){
commit('CHANGE_MOD',payload)
  }
}
