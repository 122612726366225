export default [
  {
    path: "/faq",
    name: "faq",

    component: () => import("@/views/data/general/faq/faq.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "faq",
    },
  },
  {
    path: "/faq/add-question",
    name: "add-question",

    component: () => import("@/views/data/general/faq/add-question.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "add-question",
    },
  },
  {
    path: "/faq/show-question/:id",
    name: "show-question",

    component: () => import("@/views/data/general/faq/show-question.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "show-question",
    },
  },
  {
    path: "/faq/edit-question/:id",
    name: "edit-question",

    component: () => import("@/views/data/general/faq/edit-question.vue"),
    meta: {
      auth_roles: ["All", "General"],
      authRequired: true,
      title: "edit-question",
    },
  },
];
