export default [
  {
    path: "home",
    name: "home",

    component: () => import("@/views/dashboards/default.vue"),
    meta: {
      auth_roles: ["All", "Users Management", "Users Favorites", "General", "Service Status", "APIs Settings", "Settings"],
      authRequired: true,
      title: "Home",
    },
  },
];
